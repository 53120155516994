@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,900&subset=latin-ext);
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }
  a:active, a:focus, a:hover {
    text-decoration: none; }

*, *:before, *:after {
  box-sizing: border-box; }

.flag-select {
  position: relative;
  display: inline-block;
  vertical-align: inherit;
  padding-bottom: 5px;
  color: #4d4d4d;
  text-align: left; }
  .flag-select img {
    width: 1.3em;
    height: 1.3em;
    position: relative;
    top: 0.3em; }
  .flag-select .selected--flag--option {
    cursor: pointer;
    padding: 0 8px; }
    .flag-select .selected--flag--option:before {
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 999; }
    .flag-select .selected--flag--option:focus {
      outline: none; }
    .flag-select .selected--flag--option .country-label {
      padding-left: 9px; }
  .flag-select .country-flag {
    cursor: pointer; }
    .flag-select .country-flag .country-label {
      font-size: 1em;
      position: relative; }
  .flag-select .flag-options {
    position: absolute;
    z-index: 999999;
    border: 1px solid #bdbbbb;
    border-radius: 3px;
    background: #ffffff;
    margin-top: 8px;
    padding: 8px 0;
    max-height: 100px;
    overflow: auto; }
    .flag-select .flag-options.to--left {
      right: 10px; }
  .flag-select .flag-option {
    cursor: pointer;
    padding: 0px 8px 3px 8px;
    margin: 4px 0;
    white-space: nowrap; }
    .flag-select .flag-option.has-label {
      padding: 0px 20px 3px 8px; }
    .flag-select .flag-option:hover {
      background: #eaeaea; }
    .flag-select .flag-option:focus {
      outline: none;
      background: #eaeaea; }
    .flag-select .flag-option .country-label {
      padding: 0 9px; }
  .flag-select .filterBox {
    width: 100%; }
    .flag-select .filterBox input {
      width: 90%;
      margin: 0 4%; }
      .flag-select .filterBox input:focus {
        outline: none; }
  .flag-select .arrow-down {
    color: #4d4d4d;
    padding: 2px; }
  .flag-select .hidden {
    display: none; }
  .flag-select .no--focus {
    pointer-events: none; }

body,
input,
textarea,
button {
  font-family: "Roboto", Arial, Helvetice, sans-serif; }

h1 {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  line-height: 60px; }

.single-content h1,
.info-center h1 {
  font-size: 28px;
  line-height: 33px; }

.single-content h1 {
  text-align: left; }

h2 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500; }
  h2.content-title {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    opacity: 0.6; }

.side-menu-content h2 {
  font-size: 28px;
  line-height: 33px; }

h3 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500; }
  h3 span {
    font-weight: 300; }

h4 {
  font-size: 14px;
  line-height: 16px;
  font-weight: 300; }
  h4 .new {
    font-weight: 500;
    color: #17C63A; }
  h4 .old {
    opacity: 0.5;
    text-decoration: line-through; }

.single-content h4 {
  font-size: 18px; }

.selected-items {
  margin-bottom: 10px; }
  .selected-items h4 {
    position: relative;
    margin-bottom: 5px;
    padding-left: 35px;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400; }
    .selected-items h4:before {
      content: "";
      position: absolute;
      left: 12px;
      top: 4px;
      transform: rotate(45deg);
      width: 9px;
      height: 17px;
      border-right: transparent solid 3px;
      border-bottom: transparent solid 3px; }

p {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400; }

.info-center p {
  opacity: 0.6; }

.info-center p.emphasize {
  font-style: italic;
  opacity: 1; }

.disclaimer {
  font-size: 12px;
  text-align: center; }
  .disclaimer a {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    -webkit-text-decoration-style: solid;
            text-decoration-style: solid; }

.version-display p {
  font-size: 12px; }

.btn {
  text-transform: uppercase; }

.action-icon {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
  background: none;
  border: 0;
  outline: none; }
  .action-icon span {
    position: absolute;
    left: 22px;
    top: 50%;
    width: 16px;
    height: 2px;
    margin-top: -1px; }
    .action-icon span:before, .action-icon span:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 2px; }
    .action-icon span:before {
      top: 3px; }
    .action-icon span:after {
      top: -3px; }
  .action-icon.add {
    width: 50px; }
    .action-icon.add span {
      left: 18px; }
      .action-icon.add span:before {
        width: 16px;
        transform: rotate(90deg);
        margin-top: -3px; }
      .action-icon.add span:after {
        display: none; }
  .action-icon.back {
    width: 80px; }
    .action-icon.back span:before, .action-icon.back span:after {
      left: -2px; }
    .action-icon.back span:before {
      transform: rotate(45deg); }
    .action-icon.back span:after {
      transform: rotate(-45deg); }
  .action-icon.more span:before, .action-icon.more span:after {
    right: -2px; }
  .action-icon.more span:before {
    transform: rotate(135deg); }
  .action-icon.more span:after {
    transform: rotate(-135deg); }

.navbar-action {
  position: relative;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
  background: none;
  border: 0;
  outline: none; }
  .navbar-action span {
    position: absolute;
    left: 22px;
    top: 50%;
    width: 16px;
    height: 2px;
    margin-top: -1px; }
    .navbar-action span:before, .navbar-action span:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 2px; }
    .navbar-action span:before {
      top: -5px; }
    .navbar-action span:after {
      top: 5px; }
  .navbar-action.active span {
    background-color: transparent !important; }
    .navbar-action.active span:before {
      top: 0;
      transform: rotate(45deg); }
    .navbar-action.active span:after {
      top: 0;
      transform: rotate(-45deg); }

.arrow-down {
  display: block;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  text-align: left;
  text-indent: -9999px;
  background-color: transparent;
  border-right: transparent solid 2px;
  border-bottom: transparent solid 2px; }

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  border-width: 5px;
  border-style: solid;
  border-radius: 50%;
  transform: translate(-50%, -50%); }
  .spinner:after {
    content: "";
    position: absolute;
    left: -5px;
    top: -5px;
    width: 50px;
    height: 50px;
    border-width: 5px;
    border-style: solid;
    border-radius: 50%;
    animation: 1s linear infinite rot;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-clip-path: inset(0 0 0 25px);
            clip-path: inset(0 0 0 25px); }

.spinner-outer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1200;
  opacity: 1;
  width: 100%;
  height: 100%;
  background: #2a2c33; }

.icon-check {
  display: inline-block;
  position: relative;
  left: 5px;
  width: 15px;
  height: 15px; }
  .icon-check:before {
    content: "";
    position: absolute;
    left: -5px;
    top: 50%;
    width: 13px;
    height: 6px;
    border-left: rgba(42, 44, 51, 0.6) solid 2px;
    border-bottom: rgba(42, 44, 51, 0.6) solid 2px;
    transform: translateY(-50%) rotate(-45deg); }

@keyframes rot {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.form-fields.mt-40 {
  margin-top: 40px; }

.form-fields li {
  margin-bottom: 10px; }
  .form-fields li.mb-40 {
    margin-bottom: 40px; }
  .form-fields li.cols {
    display: flex;
    margin: 0 -5px; }
    .form-fields li.cols .sec {
      width: 70px;
      padding: 0 5px; }
    .form-fields li.cols .prim {
      flex: 1 1;
      padding: 0 5px; }

.form-fields label {
  display: block;
  opacity: 0.6;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  text-align: initial; }

.form-fields textarea, .form-fields input {
  width: 100%;
  padding: 20px;
  font-size: 18px; }

.form-fields textarea {
  height: 100px; }

.form-fields input {
  height: 60px;
  border-width: 1px; }

.flag-select-outer {
  position: relative;
  height: 60px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  border-radius: 3px; }
  .flag-select-outer .flag-select {
    display: block; }
    .flag-select-outer .flag-select .selected--flag--option {
      position: relative;
      padding: 15px 8px 22px; }
      .flag-select-outer .flag-select .selected--flag--option .arrow-down {
        position: absolute;
        top: 50%;
        right: 20px;
        margin-top: -9px; }
    .flag-select-outer .flag-select .country-flag {
      width: 100%;
      height: auto; }
    .flag-select-outer .flag-select .flag-options {
      width: 100%;
      border: 0; }
      .flag-select-outer .flag-select .flag-options .flag-option {
        padding: 5px 10px; }

.select-outer {
  position: relative;
  height: 60px;
  border-radius: 3px;
  border: transparent solid 1px; }
  .select-outer select {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 58px;
    padding: 0 15px;
    font-size: 20px;
    border: 0;
    -webkit-appearance: none;
    outline: none; }
  .select-outer:before {
    content: "";
    position: absolute;
    right: 15px;
    top: 20px;
    z-index: 2;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    text-align: left;
    text-indent: -9999px;
    background-color: transparent;
    border-right: transparent solid 2px;
    border-bottom: transparent solid 2px; }

.custom-check,
.custom-radio {
  display: block;
  position: relative;
  margin-bottom: 12px; }
  .custom-check label,
  .custom-radio label {
    position: relative;
    opacity: 1;
    padding: 10px 0 10px 50px;
    line-height: 20px; }
    .custom-check label span,
    .custom-radio label span {
      opacity: 0.6; }
    .custom-check label:before,
    .custom-radio label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 40px;
      height: 40px;
      background: #FFFFFF;
      border-width: 1px;
      border-style: solid;
      border-radius: 3px; }
    .custom-check label:after,
    .custom-radio label:after {
      border-right: transparent solid 3px;
      border-bottom: transparent solid 3px; }
  .custom-check input[type="checkbox"],
  .custom-check input[type="radio"],
  .custom-radio input[type="checkbox"],
  .custom-radio input[type="radio"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    opacity: 0;
    margin-right: 10px; }
    .custom-check input[type="checkbox"]:checked + label span,
    .custom-check input[type="radio"]:checked + label span,
    .custom-radio input[type="checkbox"]:checked + label span,
    .custom-radio input[type="radio"]:checked + label span {
      opacity: 1; }
    .custom-check input[type="checkbox"]:checked + label:after,
    .custom-check input[type="radio"]:checked + label:after,
    .custom-radio input[type="checkbox"]:checked + label:after,
    .custom-radio input[type="radio"]:checked + label:after {
      content: "";
      position: absolute;
      left: 16px;
      top: 9px;
      transform: rotate(45deg);
      width: 9px;
      height: 17px; }

.rc-input-number {
  display: block;
  position: relative;
  height: 60px;
  margin: 0;
  padding: 0;
  line-height: 60px;
  font-size: 20px;
  border: 1px solid transparent;
  border-radius: 3px; }
  .rc-input-number .rc-input-number-handler {
    touch-action: none; }
  .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-up,
  .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-down {
    position: absolute;
    top: 0;
    width: 60px;
    height: 100%; }
    .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-up:after,
    .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-down:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 2px;
      background-color: #444; }
    .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-up .rc-input-number-handler-up-inner,
    .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-up .rc-input-number-handler-down-inner,
    .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-down .rc-input-number-handler-up-inner,
    .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-down .rc-input-number-handler-down-inner {
      -ms-user-select: none;
      user-select: none;
      -webkit-user-select: none; }
  .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-up {
    right: 0; }
    .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-up:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 2px;
      height: 16px;
      background-color: #444; }
  .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-down {
    left: 0; }
  .rc-input-number .rc-input-number-input-wrap {
    overflow: hidden;
    height: 100%; }
    .rc-input-number .rc-input-number-input-wrap .rc-input-number-input {
      width: 100%;
      height: 100%;
      padding: 0;
      font-size: 20px;
      text-align: center;
      color: #666666;
      transition: all 0.3s ease;
      background: none;
      border: 0;
      outline: 0;
      -moz-appearance: textfield; }

.rc-input-number-handler-down-disabled,
.rc-input-number-handler-up-disabled {
  opacity: 0.72; }

.rc-input-number-disabled .rc-input-number-input {
  opacity: 0.72;
  cursor: not-allowed; }

.rc-input-number-disabled .rc-input-number-handler {
  opacity: 0.72; }

.form-fields li.upsell-box {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 40px;
  padding: 40px 20px; }
  .form-fields li.upsell-box .custom-check label,
  .form-fields li.upsell-box .custom-radio label {
    position: relative;
    padding-top: 0;
    padding-bottom: 0; }
    .form-fields li.upsell-box .custom-check label > span,
    .form-fields li.upsell-box .custom-radio label > span {
      display: block;
      position: relative;
      overflow: hidden;
      padding: 10px 65px 10px 10px;
      line-height: 19px;
      font-size: 16px;
      font-weight: 500;
      border-radius: 3px; }
      .form-fields li.upsell-box .custom-check label > span em,
      .form-fields li.upsell-box .custom-radio label > span em {
        font-style: normal;
        font-size: 14px;
        font-weight: 400; }
      .form-fields li.upsell-box .custom-check label > span img,
      .form-fields li.upsell-box .custom-radio label > span img {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: auto; }
    .form-fields li.upsell-box .custom-check label:after,
    .form-fields li.upsell-box .custom-radio label:after {
      top: 19px; }
    .form-fields li.upsell-box .custom-check label:before,
    .form-fields li.upsell-box .custom-radio label:before {
      top: 10px; }

.btn {
  display: block;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: white;
  border-radius: 3px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25); }
  .btn .btn-icon {
    display: inline-block;
    margin-right: 8px;
    vertical-align: text-top; }
    .btn .btn-icon.bill {
      margin-top: 4px; }
    .btn .btn-icon.bell {
      margin-top: 2px; }
    .btn .btn-icon.receipt {
      margin-top: 1px; }
    .btn .btn-icon.go {
      margin-top: 1px; }
    .btn .btn-icon.back {
      margin-right: -4px; }
  .btn .spinner {
    display: none; }
  .btn.inline {
    display: inline-block; }
  .btn.large {
    height: 50px;
    padding: 0 30px;
    line-height: 50px;
    font-size: 18px; }
    .btn.large .action-icon {
      width: 20px;
      height: 25px; }
      .btn.large .action-icon span {
        top: 12px;
        left: 0; }
  .btn.medium {
    height: 40px;
    padding: 0 20px;
    line-height: 40px;
    font-size: 16px; }
  .btn.icon .btn-icon {
    margin-right: 0; }
  .btn.ghost {
    border-width: 1px;
    border-style: solid;
    box-shadow: none !important; }
  .btn.flat {
    font-size: 16px;
    background: none !important;
    border-width: 0;
    box-shadow: none !important; }
    .btn.flat .action-icon {
      margin-top: -2px; }
  .btn.disabled {
    opacity: 0.5;
    box-shadow: none; }
  .btn.floating {
    border-radius: 25px; }
  .btn.close-popup {
    border-radius: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 50; }
  .btn.secondary {
    box-shadow: none; }
  .btn.loading {
    position: relative; }
    .btn.loading:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px; }
    .btn.loading .spinner {
      z-index: 10;
      display: block;
      width: 26px;
      height: 26px;
      border-width: 3px; }
      .btn.loading .spinner:after {
        left: -3px;
        top: -3px;
        width: 26px;
        height: 26px;
        border-width: 3px;
        -webkit-clip-path: inset(0 0 0 13px);
                clip-path: inset(0 0 0 13px); }
  .btn .inline-icon {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 100%;
    margin-right: 7px;
    vertical-align: middle; }
    .btn .inline-icon:before, .btn .inline-icon:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 47%; }
    .btn .inline-icon.close:before, .btn .inline-icon.close:after {
      width: 16px;
      height: 2px; }
    .btn .inline-icon.close:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .btn .inline-icon.close:after {
      transform: translate(-50%, -50%) rotate(-45deg); }
    .btn .inline-icon.ok:before, .btn .inline-icon.ok:after {
      height: 2px; }
    .btn .inline-icon.ok:before {
      width: 20px;
      transform: translate(-50%, -50%) rotate(-45deg); }
    .btn .inline-icon.ok:after {
      width: 10px;
      transform: translate(-156%, 180%) rotate(45deg); }
  .btn.reduced-padding {
    padding: 0 5px; }

.single .cart-info .btn.medium,
.cart .cart-info .btn.medium {
  height: 50px;
  line-height: 50px;
  font-size: 18px; }
  .single .cart-info .btn.medium .btn-icon,
  .cart .cart-info .btn.medium .btn-icon {
    width: 23px;
    height: 23px; }

.single .cart-info h3,
.cart .cart-info h3 {
  font-size: 20px; }

.inline-button-holder {
  margin-bottom: 30px;
  text-align: center; }
  .inline-button-holder.pull-top {
    margin-top: -30px; }
  .inline-button-holder.space-bottom {
    margin-bottom: 100px; }
  .inline-button-holder.frame {
    border-style: solid;
    border-radius: 3px;
    border-width: 1px; }
    .inline-button-holder.frame .btn .btn-icon.back {
      margin-top: -3px; }

.left-action button.back {
  padding-left: 35px;
  font-size: inherit; }

.button-group {
  display: flex;
  padding: 10px 5px; }
  .button-group .col {
    flex: 1 1;
    flex-basis: 50%;
    box-sizing: border-box;
    padding: 0 5px; }
  .button-group.bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%; }
  .button-group.sent {
    display: block; }
    .button-group.sent p {
      padding: 10px 0;
      text-align: center; }

.modal-outer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  overflow-y: auto; }

.modal-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; }
  .modal-header .close-modal {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateX(-50%); }
    .modal-header .close-modal:before, .modal-header .close-modal:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 2px; }
    .modal-header .close-modal:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .modal-header .close-modal:after {
      transform: translate(-50%, -50%) rotate(-45deg); }

.modal-content-outer {
  padding-top: 60px; }
  .modal-content-outer .survey-hero {
    max-width: 100%; }
  .modal-content-outer .modal-content-inner {
    padding: 20px 30px 100px; }
    .modal-content-outer .modal-content-inner h3 {
      margin-bottom: 20px;
      font-size: 22px;
      line-height: 28px;
      font-weight: 500; }

.radio-item-list li {
  margin-bottom: 10px; }
  .radio-item-list li .radio-item {
    display: block;
    position: relative; }
    .radio-item-list li .radio-item label {
      display: block;
      position: relative;
      z-index: 2;
      padding: 15px 10px 15px 50px;
      font-size: 18px;
      line-height: 20px;
      border-radius: 25px;
      box-sizing: border-box; }
      .radio-item-list li .radio-item label:before {
        content: "";
        position: absolute;
        left: 10px;
        top: 10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        box-sizing: border-box; }
      .radio-item-list li .radio-item label:after {
        content: "";
        position: absolute;
        left: 17px;
        top: 17px;
        display: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        box-sizing: border-box; }
    .radio-item-list li .radio-item input[type="radio"] {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 50px; }
      .radio-item-list li .radio-item input[type="radio"]:checked + label:after {
        display: block; }
      .radio-item-list li .radio-item input[type="radio"]:disabled + label {
        overflow: hidden; }
        .radio-item-list li .radio-item input[type="radio"]:disabled + label em {
          position: relative;
          z-index: 2;
          opacity: 0.5; }
        .radio-item-list li .radio-item input[type="radio"]:disabled + label:before, .radio-item-list li .radio-item input[type="radio"]:disabled + label:after {
          z-index: 2; }
        .radio-item-list li .radio-item input[type="radio"]:disabled + label .result-stripe {
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0.1;
          height: 100%; }
        .radio-item-list li .radio-item input[type="radio"]:disabled + label .result-number {
          position: absolute;
          right: 10px;
          top: 0;
          opacity: 0.5;
          font-size: 18px;
          line-height: 50px; }
      .radio-item-list li .radio-item input[type="radio"]:disabled:checked + label em {
        opacity: 1; }
      .radio-item-list li .radio-item input[type="radio"]:disabled:checked + label .result-stripe,
      .radio-item-list li .radio-item input[type="radio"]:disabled:checked + label .result-number {
        opacity: 1; }

body {
  overflow-x: hidden; }
  body.theme-light {
    background-color: #f7f8fa; }
  body.theme-dark {
    background-color: #2a2c33; }

.app-inner {
  padding: 70px 10px 10px 10px;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll; }
  .app-inner.single {
    padding: 60px 0 0 0; }
  .app-inner.not-found {
    height: 100vh;
    padding: 0; }
  .app-inner.has-error {
    padding-top: 130px; }

.main-header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 60px; }
  .main-header .left-action {
    width: 83px; }
  .main-header .right-action {
    width: 70px; }
    .main-header .right-action .navbar-action {
      margin-left: 10px; }
  .main-header h1 {
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

/*.single {
	.main-header {
		background-color: transparent !important;
	}
}*/
.history .main-header h1 {
  font-size: 15px; }

.landing-outer {
  position: relative;
  height: 100vh;
  padding-top: 5vh; }
  .landing-outer figure {
    width: 250px;
    height: 142px;
    margin: 0 auto; }
    .landing-outer figure img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      margin: 0 auto; }
  .landing-outer .landing-choice {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 2;
    transform: translateY(-40%);
    width: 100%;
    padding: 0 20px; }
    .landing-outer .landing-choice .flag-select-outer {
      margin-bottom: 20px; }
      .landing-outer .landing-choice .flag-select-outer .flag-select .flag-options {
        max-height: 170px; }
    .landing-outer .landing-choice .landing-bookmark-info {
      margin: 10px 0; }
    .landing-outer .landing-choice .delivery-phone {
      margin: 20px 0 10px;
      text-align: center; }
      .landing-outer .landing-choice .delivery-phone a {
        color: inherit;
        text-decoration: underline; }
  .landing-outer .intro-bgr {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    height: 50vh;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end; }
    .landing-outer .intro-bgr:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
    .landing-outer .intro-bgr .version-display {
      position: unset;
      margin-right: 10px; }
    .landing-outer .intro-bgr .banner-bottom {
      position: unset;
      z-index: 2; }
  .landing-outer .banner-bottom {
    z-index: 100; }

.single .main-header {
  /*.action-icon,
		.navbar-action {
			span {
				background-color: white;
				&:before,
				&:after {
					background-color: white;
				}
			}
		}*/ }
  .single .main-header .left-action {
    flex: 1 1; }

.single .gallery {
  position: relative; }
  .single .gallery:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%); }
  .single .gallery img {
    display: block;
    max-width: 100%;
    height: auto; }

.single .single-content {
  padding: 20px;
  margin-bottom: 60px; }
  .single .single-content h1 {
    margin-bottom: 10px; }
  .single .single-content h4 {
    margin-bottom: 10px; }
  .single .single-content p {
    opacity: 0.6; }

.category-info {
  margin-bottom: 10px; }

.article-list {
  padding-bottom: 90px; }
  .article-list article {
    margin-bottom: 10px;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid; }
    .article-list article .inner-article {
      display: flex;
      align-items: center;
      align-content: stretch;
      height: 60px; }
      .article-list article .inner-article figure {
        position: relative;
        width: 60px;
        height: 60px;
        margin-right: 10px; }
        .article-list article .inner-article figure img {
          max-width: 100%;
          height: auto; }
        .article-list article .inner-article figure .list-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 40px;
          height: 40px; }
        .article-list article .inner-article figure.default img {
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
      .article-list article .inner-article.unavailable figure {
        -webkit-filter: grayscale(1);
                filter: grayscale(1); }
      .article-list article .inner-article .content {
        flex: 1 1; }
        .article-list article .inner-article .content h3 {
          margin-bottom: 3px; }
      .article-list article .inner-article .toggle {
        position: relative;
        width: 60px;
        height: 60px; }
        .article-list article .inner-article .toggle .arrow-down {
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -8px 0 0 -4px;
          transition: all 300ms ease; }
        .article-list article .inner-article .toggle.open .arrow-down {
          transform: rotate(-135deg); }
      .article-list article .inner-article .item-price {
        display: flex;
        justify-content: space-between; }
    .article-list article .cart-more .more-content {
      opacity: 0;
      visibility: hidden;
      height: 0;
      transition: all 50ms ease-out; }
    .article-list article .cart-more.open .more-content {
      opacity: 1;
      visibility: visible;
      height: auto;
      padding: 10px;
      transition: all 200ms ease-in; }
    .article-list article.subcategory .content {
      height: 36px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .article-list article.subcategory .content h3 {
        margin-left: 20px; }
    .article-list article.article-featured {
      position: relative; }
      .article-list article.article-featured .content h3 {
        padding-right: 30px;
        font-weight: 900; }
      .article-list article.article-featured .article-featured-logo {
        position: absolute;
        top: 50%;
        right: 50px;
        width: 36px;
        height: 36px;
        transform: translateY(-50%); }
        .article-list article.article-featured .article-featured-logo img {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 50%; }
      .article-list article.article-featured:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 67px;
        height: 100%; }
  .article-list.category article .inner-article {
    height: 70px; }
    .article-list.category article .inner-article figure {
      width: 70px;
      height: 70px;
      margin-right: 0;
      padding: 5px; }
  .article-list.combo-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 13px; }
    .article-list.combo-list article {
      margin: 0; }
      .article-list.combo-list article .inner-article {
        flex-direction: column;
        height: auto; }
        .article-list.combo-list article .inner-article figure {
          height: auto;
          width: 100%;
          margin: 0; }
          .article-list.combo-list article .inner-article figure img {
            display: block;
            border-radius: 3px 3px 0 0; }
        .article-list.combo-list article .inner-article .content {
          padding: 10px; }
          .article-list.combo-list article .inner-article .content h3 {
            margin-bottom: 5px;
            font-size: 22px;
            line-height: 24px; }
          .article-list.combo-list article .inner-article .content h4 {
            font-size: 18px;
            line-height: 21px; }
        .article-list.combo-list article .inner-article .action-icon.add {
          height: 40px;
          width: 100%; }
          .article-list.combo-list article .inner-article .action-icon.add span {
            left: 50%;
            transform: translateX(-50%); }
  .article-list .delivery-phone {
    margin-bottom: 10px;
    text-align: center; }
    .article-list .delivery-phone a {
      color: inherit;
      text-decoration: underline; }

.article-list-simple {
  padding-bottom: 60px; }
  .article-list-simple .article-simple {
    margin-bottom: 20px; }
  .article-list-simple .headline {
    display: flex;
    padding: 10px;
    background-color: white;
    border: #D9D9D9 solid 1px; }
    .article-list-simple .headline p {
      flex: auto;
      font-size: 14px;
      line-height: 20px; }
      .article-list-simple .headline p:last-child {
        text-align: right; }
  .article-list-simple table {
    width: 100%;
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-top-width: 0; }
    .article-list-simple table tr td,
    .article-list-simple table tr th {
      padding: 15px 0 10px;
      vertical-align: top;
      border-bottom-width: 1px;
      border-bottom-style: solid; }
      .article-list-simple table tr td p,
      .article-list-simple table tr th p {
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 19px;
        color: rgba(42, 44, 51, 0.6); }
      .article-list-simple table tr td ul li,
      .article-list-simple table tr th ul li {
        margin-bottom: 2px;
        font-size: 14px;
        line-height: 16px;
        color: rgba(42, 44, 51, 0.6); }
        .article-list-simple table tr td ul li:before,
        .article-list-simple table tr th ul li:before {
          content: "- "; }
    .article-list-simple table tr th {
      width: 30px;
      text-align: center; }
    .article-list-simple table tr td.total p {
      text-align: left; }
      .article-list-simple table tr td.total p span {
        float: right; }
    .article-list-simple table tr td:last-child {
      padding-right: 10px;
      text-align: right; }
      .article-list-simple table tr td:last-child p {
        white-space: nowrap; }
    .article-list-simple table tr:last-child td,
    .article-list-simple table tr:last-child th {
      border-bottom: 0; }

.meals-and-category-list {
  padding-top: 130px;
  padding-bottom: 60px; }
  .meals-and-category-list.no-cart {
    padding-top: 70px; }

.banner-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%; }
  .banner-bottom img {
    display: block;
    max-width: 100%;
    height: auto; }

.banner-top {
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 40px; }
  .banner-top img {
    display: block;
    width: 100%;
    height: auto; }

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  display: none;
  background: rgba(0, 0, 0, 0.5); }

.side-menu {
  position: fixed;
  left: 0;
  bottom: -200vh;
  z-index: 2000;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 90vh;
  height: calc(1vh * 90);
  height: calc(var(--vh, 1vh) * 90);
  transition: all 200ms ease; }
  .side-menu .navbar-action {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    width: 40px;
    height: 40px;
    background-color: black;
    border-radius: 50%; }
    .side-menu .navbar-action span:before, .side-menu .navbar-action span:after {
      left: -10px; }
  .side-menu .side-menu-content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 40px;
    text-align: center; }
    .side-menu .side-menu-content figure {
      width: 250px;
      height: 142px;
      margin: 0 auto 30px; }
      .side-menu .side-menu-content figure img {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto; }
    .side-menu .side-menu-content .btn,
    .side-menu .side-menu-content .flag-select-outer {
      margin-bottom: 20px; }
    .side-menu .side-menu-content h2 {
      margin-bottom: 40px; }
    .side-menu .side-menu-content p {
      opacity: 0.6; }
    .side-menu .side-menu-content .hide {
      display: none; }
    .side-menu .side-menu-content .show {
      display: block; }
    .side-menu .side-menu-content .button-vertical-separator {
      margin: 0 0px 15px;
      text-transform: uppercase; }

.main-header.active .background-overlay, .cart .active .background-overlay, .banner.active .background-overlay {
  display: block; }

.main-header.active .side-menu, .cart .active .side-menu, .banner.active .side-menu {
  bottom: 0;
  opacity: 1;
  visibility: visible;
  transition: all 200ms ease; }

.cart .active .background-overlay, .banner.active .background-overlay {
  z-index: 2100; }

.cart .active .side-menu, .banner.active .side-menu {
  z-index: 2200; }

.confirmation-popup {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2100;
  width: 100%;
  height: 90vh;
  height: calc(1vh * 90);
  height: calc(var(--vh, 1vh) * 90); }
  .confirmation-popup .confirmation-popup-content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 40px;
    text-align: center; }
    .confirmation-popup .confirmation-popup-content h2 {
      margin-bottom: 40px; }

.popup .background-overlay {
  z-index: 2050;
  display: block; }

.cart-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 6px; }
  .cart-info .left-info {
    flex: 1 1; }
  .cart-info .right-info {
    flex: 1 1; }
    .cart-info .right-info .reduced-width {
      margin: 0 15px;
      padding: 0 15px;
      border-radius: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }

.single .cart-info,
.cart .cart-info {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  padding: 10px;
  margin: 0; }
  .single .cart-info > div,
  .cart .cart-info > div {
    flex: 1 1;
    text-align: center; }

.cart .cart-info {
  flex-flow: column;
  align-items: stretch; }

.cart .cart-info .left-info {
  margin: 0 0 10px; }

.info-center {
  position: relative;
  top: 50%;
  transform: translateY(-60%);
  padding: 30px;
  text-align: center; }
  .info-center h1,
  .info-center p {
    margin-bottom: 40px; }

.order-confirmation {
  padding: 30px 30px 110px;
  text-align: center; }
  .order-confirmation h1,
  .order-confirmation p {
    margin-bottom: 40px; }

.cookie-policy {
  padding: 60px 30px 30px; }
  .cookie-policy h2 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold; }
  .cookie-policy h3 {
    margin-bottom: 20px;
    font-size: 1.2em; }
  .cookie-policy h4 {
    margin-bottom: 10px;
    font-size: 1.1em;
    font-weight: bold; }
  .cookie-policy p {
    margin-bottom: 20px; }
  .cookie-policy ul, .cookie-policy ol {
    margin-bottom: 20px; }
  .cookie-policy li {
    list-style: disc;
    margin-left: 15px; }
  .cookie-policy em {
    font-style: italic; }
  .cookie-policy strong {
    font-weight: bold; }
  .cookie-policy table {
    margin-bottom: 20px; }
    .cookie-policy table tr, .cookie-policy table td, .cookie-policy table th {
      border: solid thin;
      padding: 5px; }
    .cookie-policy table th {
      font-style: italic; }

.disclaimer {
  margin-top: 25px; }

.switch-wrapper {
  position: relative; }

.switch-wrapper > div {
  position: absolute;
  width: 100%;
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: hidden; }

.inline-error {
  position: fixed;
  left: 0;
  top: 60px;
  z-index: 2;
  width: 100%;
  padding: 15px 10px;
  background-color: #FF4040; }
  .inline-error p {
    font-size: 14px;
    line-height: 20px;
    color: white !important;
    text-align: center; }

.status-bar {
  padding-bottom: 20px;
  text-align: center; }
  .status-bar p {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500; }
  .status-bar .inline-icon {
    display: inline-block;
    width: 21px;
    height: 21px;
    margin: 0 3px 0 5px;
    vertical-align: middle; }

.floating-container {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  transition: bottom 200ms ease;
  display: flex;
  justify-content: center; }

.scrolling-up .floating-container {
  bottom: 80px; }

.cart-info-container {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 90; }

.version-display {
  position: absolute;
  bottom: 10px;
  right: 10px; }

.btn .phone-number {
  display: none; }

.working-hours-container a {
  color: inherit;
  text-decoration: underline; }

.delivery-price {
  font-size: smaller;
  margin-top: 0.5em; }

.working-hours {
  margin-top: 1em; }

.info-message {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 2200; }
  .info-message .info-text {
    margin: auto;
    padding: 20px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.75);
    color: white;
    font-size: large;
    pointer-events: none; }

@media (min-width: 768px) {
  .app-outer,
  .main-header,
  .side-menu,
  .banner-bottom,
  .cart-info,
  .modal-outer,
  .button-group,
  .modal-header {
    max-width: 500px; }
  .app-outer,
  .modal-outer,
  .button-group {
    margin: 0 auto; }
  .main-header,
  .side-menu,
  .banner-bottom,
  .cart .cart-info,
  .single .cart-info,
  .modal-outer,
  .modal-header,
  .button-group.bottom {
    left: unset; }
  .main-header {
    margin-left: -10px; }
  .btn .phone-number {
    display: unset; }
  .btn .call-label {
    display: none; } }

.theme-light .main-header {
  background-color: #f7f8fa; }
  .theme-light .main-header .left-action button.back {
    color: #2a2c33; }

.theme-light .intro-bgr:before {
  background-image: linear-gradient(-180deg, #f7f8fa 0%, rgba(225, 226, 230, 0) 100%); }

.theme-light h1,
.theme-light h2,
.theme-light h3,
.theme-light h4,
.theme-light p {
  color: #2a2c33; }

.theme-light .selected-items h4:before {
  border-color: #2a2c33; }

.theme-light .select-outer,
.theme-light .flag-select-outer {
  background-color: #ffffff; }
  .theme-light .select-outer select,
  .theme-light .flag-select-outer select {
    color: #2a2c33;
    background-color: #ffffff; }

.theme-light .select-outer {
  border-color: rgba(0, 0, 0, 0.15); }
  .theme-light .select-outer:before {
    border-color: #2a2c33; }

.theme-light .btn {
  background-color: black; }
  .theme-light .btn.ghost {
    color: #2a2c33;
    background-color: #f7f8fa;
    border-color: rgba(0, 0, 0, 0.15); }
    .theme-light .btn.ghost .btn-icon {
      fill: #2a2c33; }
  .theme-light .btn .action-icon span {
    background-color: #ffffff; }
    .theme-light .btn .action-icon span:before, .theme-light .btn .action-icon span:after {
      background-color: #ffffff; }
  .theme-light .btn.loading:before {
    background: #2a2c33; }
  .theme-light .btn.loading .spinner {
    border-color: rgba(255, 255, 255, 0.15); }
    .theme-light .btn.loading .spinner:after {
      border-color: white; }
  .theme-light .btn.flat {
    color: #2a2c33; }
    .theme-light .btn.flat .action-icon span {
      background-color: #2a2c33; }
      .theme-light .btn.flat .action-icon span:before, .theme-light .btn.flat .action-icon span:after {
        background-color: #2a2c33; }
    .theme-light .btn.flat .action-icon.back span {
      background: none; }
    .theme-light .btn.flat .btn-icon {
      fill: #2a2c33; }
  .theme-light .btn .inline-icon:before, .theme-light .btn .inline-icon:after {
    background-color: white; }
  .theme-light .btn.secondary {
    color: rgba(42, 44, 51, 0.6);
    background-color: rgba(0, 0, 0, 0.15);
    border-color: rgba(0, 0, 0, 0.15); }
    .theme-light .btn.secondary .inline-icon:before, .theme-light .btn.secondary .inline-icon:after {
      background-color: rgba(42, 44, 51, 0.6); }

.theme-light .button-group {
  background-color: white;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1); }

.theme-light .action-icon span,
.theme-light .navbar-action span {
  background-color: #2a2c33; }
  .theme-light .action-icon span:before, .theme-light .action-icon span:after,
  .theme-light .navbar-action span:before,
  .theme-light .navbar-action span:after {
    background-color: #2a2c33; }

.theme-light .action-icon.back span,
.theme-light .navbar-action.back span {
  background: none; }

.theme-light .side-menu .navbar-action {
  background-color: #2a2c33; }
  .theme-light .side-menu .navbar-action.active span:before, .theme-light .side-menu .navbar-action.active span:after {
    background-color: white; }

.theme-light .flag-options {
  background: #ffffff; }

.theme-light .country-label {
  color: #2a2c33; }

.theme-light .arrow-down {
  border-color: #2a2c33; }

.theme-light .icon-check:before {
  border-left: rgba(42, 44, 51, 0.6) solid 2px;
  border-bottom: rgba(42, 44, 51, 0.6) solid 2px; }

.theme-light .article-list article {
  background-color: #ffffff;
  border-color: rgba(0, 0, 0, 0.15); }
  .theme-light .article-list article figure .list-icon {
    fill: #2a2c33; }
  .theme-light .article-list article.subcategory {
    background: none; }
  .theme-light .article-list article .inner-article.unavailable .content h3, .theme-light .article-list article .inner-article.unavailable .content h4 {
    color: rgba(42, 44, 51, 0.6); }
  .theme-light .article-list article.article-featured {
    background-color: #EDEEF0; }
    .theme-light .article-list article.article-featured figure {
      background-color: #ffffff; }
    .theme-light .article-list article.article-featured:before {
      background-color: #ffffff; }
  .theme-light .article-list article.promo {
    background-color: #F6BE00; }
    .theme-light .article-list article.promo figure .list-icon {
      fill: #2a2c33; }
    .theme-light .article-list article.promo .content h2 {
      color: #2a2c33; }
    .theme-light .article-list article.promo .action-icon span, .theme-light .article-list article.promo .action-icon span:before, .theme-light .article-list article.promo .action-icon span:after {
      background-color: #2a2c33; }

.theme-light .single .cart-info,
.theme-light .cart .cart-info {
  background-color: #ffffff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.1); }

.theme-light .cart-info-container {
  background-color: #f7f8fa;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.25); }

.theme-light .form-fields label {
  color: #2a2c33; }

.theme-light .form-fields textarea, .theme-light .form-fields input {
  color: #2a2c33;
  background-color: #ffffff;
  border-color: rgba(0, 0, 0, 0.15); }

.theme-light .custom-check label:before,
.theme-light .custom-radio label:before {
  background-color: #ffffff;
  border-color: rgba(0, 0, 0, 0.15); }

.theme-light .custom-check label:after,
.theme-light .custom-radio label:after {
  border-color: #343740; }

.theme-light .rc-input-number {
  background-color: #ffffff;
  border-color: rgba(0, 0, 0, 0.15); }
  .theme-light .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-up:before, .theme-light .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-up:after,
  .theme-light .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-down:before,
  .theme-light .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-down:after {
    background-color: #343740; }
  .theme-light .rc-input-number .rc-input-number-input-wrap .rc-input-number-input {
    color: #343740; }

.theme-light .cart .rc-input-number {
  background-color: #f7f8fa; }

.theme-light .side-menu,
.theme-light .confirmation-popup {
  background-color: rgba(247, 248, 250, 0.95); }

.theme-light .spinner {
  border-color: rgba(42, 44, 51, 0.15); }
  .theme-light .spinner:after {
    border-color: #2a2c33; }

.theme-light .spinner-outer {
  background: #f7f8fa; }

.theme-light .status-bar p {
  color: rgba(42, 44, 51, 0.6); }
  .theme-light .status-bar p span {
    color: #2a2c33; }
  .theme-light .status-bar p .inline-icon {
    fill: black; }

.theme-light .article-list-simple .headline {
  background-color: white;
  border-color: #D9D9D9; }

.theme-light .article-list-simple table {
  background-color: white;
  border-color: #D9D9D9; }
  .theme-light .article-list-simple table tr td,
  .theme-light .article-list-simple table tr th {
    border-bottom-color: #D9D9D9; }
    .theme-light .article-list-simple table tr td p,
    .theme-light .article-list-simple table tr th p {
      color: rgba(42, 44, 51, 0.6); }
    .theme-light .article-list-simple table tr td ul li,
    .theme-light .article-list-simple table tr th ul li {
      color: rgba(42, 44, 51, 0.6); }

.theme-light .inline-button-holder.frame {
  border-color: #1F2126; }

.theme-light .disclaimer {
  color: rgba(42, 44, 51, 0.6); }
  .theme-light .disclaimer a {
    color: rgba(42, 44, 51, 0.6); }

.theme-light .cookie-policy a {
  color: rgba(42, 44, 51, 0.6); }

.theme-light .cookie-policy li {
  color: rgba(42, 44, 51, 0.95); }

.theme-light .cookie-policy table {
  color: rgba(42, 44, 51, 0.85); }

.theme-light .version-display p {
  color: rgba(42, 44, 51, 0.6);
  text-shadow: 0 0 8px #f7f8fa; }

.theme-light .form-fields li.upsell-box {
  background-color: white; }
  .theme-light .form-fields li.upsell-box .custom-check label > span,
  .theme-light .form-fields li.upsell-box .custom-radio label > span {
    background-color: #f7f8fa;
    border: solid 1px rgba(0, 0, 0, 0.15); }
  .theme-light .form-fields li.upsell-box .custom-check label:before,
  .theme-light .form-fields li.upsell-box .custom-radio label:before {
    background-color: #f7f8fa; }
  .theme-light .form-fields li.upsell-box .custom-check input[type="checkbox"]:checked + label span,
  .theme-light .form-fields li.upsell-box .custom-check input[type="radio"]:checked + label span,
  .theme-light .form-fields li.upsell-box .custom-radio input[type="checkbox"]:checked + label span,
  .theme-light .form-fields li.upsell-box .custom-radio input[type="radio"]:checked + label span {
    background-color: #EDEEF0; }

.theme-light .modal-outer {
  background-color: #f7f8fa; }

.theme-light .modal-header {
  background-color: #f7f8fa; }
  .theme-light .modal-header .close-modal:before, .theme-light .modal-header .close-modal:after {
    background-color: #2a2c33; }

.theme-light .radio-item-list li .radio-item label {
  background-color: #EDEEF0;
  border: solid 1px rgba(0, 0, 0, 0.15); }
  .theme-light .radio-item-list li .radio-item label:before {
    background-color: #f7f8fa;
    border: solid 1px rgba(0, 0, 0, 0.15); }

.theme-light .radio-item-list li .radio-item input[type="radio"]:checked + label {
  color: white;
  background-color: rgba(42, 44, 51, 0.85);
  border-color: rgba(42, 44, 51, 0.85); }
  .theme-light .radio-item-list li .radio-item input[type="radio"]:checked + label:after {
    background-color: #2a2c33; }

.theme-light .radio-item-list li .radio-item input[type="radio"]:disabled + label .result-stripe {
  background-color: #2a2c33; }

.theme-dark .main-header {
  background-color: #2a2c33; }
  .theme-dark .main-header .left-action button.back {
    color: #ffffff; }

.theme-dark .intro-bgr:before {
  background-image: linear-gradient(-180deg, #2a2c33 0%, rgba(42, 44, 51, 0) 100%); }

.theme-dark h1,
.theme-dark h2,
.theme-dark h3,
.theme-dark h4,
.theme-dark p {
  color: #ffffff; }

.theme-dark .selected-items h4:before {
  border-color: #ffffff; }

.theme-dark .select-outer,
.theme-dark .flag-select-outer {
  background-color: #343740; }
  .theme-dark .select-outer select,
  .theme-dark .flag-select-outer select {
    color: #ffffff;
    background-color: #343740; }

.theme-dark .select-outer {
  border-color: #1F2126; }
  .theme-dark .select-outer:before {
    border-color: #ffffff; }

.theme-dark .btn {
  color: #2a2c33;
  background-color: #ffffff; }
  .theme-dark .btn .btn-icon {
    fill: #2a2c33; }
  .theme-dark .btn.ghost {
    background-color: #2a2c33;
    border-color: #1F2126; }
    .theme-dark .btn.ghost .btn-icon {
      fill: #ffffff; }
  .theme-dark .btn .action-icon span {
    background-color: #2a2c33; }
    .theme-dark .btn .action-icon span:before, .theme-dark .btn .action-icon span:after {
      background-color: #2a2c33; }
  .theme-dark .btn.loading:before {
    background: #ffffff; }
  .theme-dark .btn.loading .spinner {
    border-color: rgba(42, 44, 51, 0.15); }
    .theme-dark .btn.loading .spinner:after {
      border-color: #2a2c33; }
  .theme-dark .btn.flat {
    color: #ffffff; }
    .theme-dark .btn.flat .action-icon span {
      background-color: #ffffff; }
      .theme-dark .btn.flat .action-icon span:before, .theme-dark .btn.flat .action-icon span:after {
        background-color: #ffffff; }
    .theme-dark .btn.flat .action-icon.back span {
      background: none; }
    .theme-dark .btn.flat .btn-icon {
      fill: #ffffff; }
  .theme-dark .btn .inline-icon:before, .theme-dark .btn .inline-icon:after {
    background-color: #2a2c33; }
  .theme-dark .btn.secondary {
    color: rgba(247, 248, 250, 0.6);
    background-color: rgba(247, 248, 250, 0.15);
    border-color: rgba(247, 248, 250, 0.15); }
    .theme-dark .btn.secondary .inline-icon:before, .theme-dark .btn.secondary .inline-icon:after {
      background-color: rgba(247, 248, 250, 0.6); }

.theme-dark .button-group {
  background-color: #2a2c33;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1); }

.theme-dark .action-icon span,
.theme-dark .navbar-action span {
  background-color: white; }
  .theme-dark .action-icon span:before, .theme-dark .action-icon span:after,
  .theme-dark .navbar-action span:before,
  .theme-dark .navbar-action span:after {
    background-color: white; }

.theme-dark .action-icon.back span,
.theme-dark .navbar-action.back span {
  background: none; }

.theme-dark .side-menu .navbar-action {
  background-color: white; }
  .theme-dark .side-menu .navbar-action.active span:before, .theme-dark .side-menu .navbar-action.active span:after {
    background-color: #2a2c33; }

.theme-dark .flag-options {
  background: #343740; }

.theme-dark .country-label {
  color: #f7f8fa; }

.theme-dark .arrow-down {
  border-color: #f7f8fa; }

.theme-dark .icon-check:before {
  border-left: rgba(247, 248, 250, 0.6) solid 2px;
  border-bottom: rgba(247, 248, 250, 0.6) solid 2px; }

.theme-dark .article-list article {
  background-color: #343740;
  border-color: #1F2126; }
  .theme-dark .article-list article figure .list-icon {
    fill: #f7f8fa; }
  .theme-dark .article-list article.subcategory {
    background: none; }
  .theme-dark .article-list article .inner-article.unavailable .content h3, .theme-dark .article-list article .inner-article.unavailable .content h4 {
    color: rgba(247, 248, 250, 0.6); }
  .theme-dark .article-list article.article-featured {
    background-color: #494D59; }
    .theme-dark .article-list article.article-featured figure {
      background-color: #343740; }
    .theme-dark .article-list article.article-featured:before {
      background-color: #343740; }
  .theme-dark .article-list article.promo {
    background-color: #F6BE00; }
    .theme-dark .article-list article.promo figure .list-icon {
      fill: #2a2c33; }
    .theme-dark .article-list article.promo .content h2 {
      color: #2a2c33; }
    .theme-dark .article-list article.promo .action-icon span, .theme-dark .article-list article.promo .action-icon span:before, .theme-dark .article-list article.promo .action-icon span:after {
      background-color: #2a2c33; }

.theme-dark .article-list .delivery-phone {
  color: rgba(247, 248, 250, 0.6); }

.theme-dark .single .cart-info,
.theme-dark .cart .cart-info {
  background-color: #343740;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.25); }

.theme-dark .cart-info-container {
  background-color: #2a2c33;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.25); }

.theme-dark .form-fields label {
  color: #f7f8fa; }

.theme-dark .form-fields textarea, .theme-dark .form-fields input {
  color: #ffffff;
  background-color: #343740;
  border-color: #1F2126; }

.theme-dark .custom-check label:before,
.theme-dark .custom-radio label:before {
  background-color: #343740;
  border-color: #1F2126; }

.theme-dark .custom-check label:after,
.theme-dark .custom-radio label:after {
  border-color: #ffffff; }

.theme-dark .rc-input-number {
  background-color: #343740;
  border-color: #1F2126; }
  .theme-dark .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-up:before, .theme-dark .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-up:after,
  .theme-dark .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-down:before,
  .theme-dark .rc-input-number .rc-input-number-handler-wrap .rc-input-number-handler-down:after {
    background-color: #ffffff; }
  .theme-dark .rc-input-number .rc-input-number-input-wrap .rc-input-number-input {
    color: #ffffff; }

.theme-dark .cart .rc-input-number {
  background-color: #2a2c33; }

.theme-dark .side-menu,
.theme-dark .confirmation-popup {
  background-color: rgba(42, 44, 51, 0.95); }

.theme-dark .spinner {
  border-color: rgba(255, 255, 255, 0.15); }
  .theme-dark .spinner:after {
    border-color: white; }

.theme-dark .spinner-outer {
  background: #2a2c33; }

.theme-dark .status-bar p {
  color: rgba(247, 248, 250, 0.6); }
  .theme-dark .status-bar p span {
    color: white; }
  .theme-dark .status-bar p .inline-icon {
    fill: white; }

.theme-dark .article-list-simple .headline {
  background-color: #343740;
  border-color: #1F2126; }

.theme-dark .article-list-simple table {
  background-color: #343740;
  border-bottom-color: #1F2126; }
  .theme-dark .article-list-simple table tr td,
  .theme-dark .article-list-simple table tr th {
    border-color: #1F2126; }
    .theme-dark .article-list-simple table tr td p,
    .theme-dark .article-list-simple table tr th p {
      color: rgba(247, 248, 250, 0.6); }
    .theme-dark .article-list-simple table tr td ul li,
    .theme-dark .article-list-simple table tr th ul li {
      color: rgba(247, 248, 250, 0.6); }

.theme-dark .inline-button-holder.frame {
  border-color: #eaeaea; }

.theme-dark .disclaimer {
  color: rgba(247, 248, 250, 0.6); }
  .theme-dark .disclaimer a {
    color: rgba(247, 248, 250, 0.6); }

.theme-dark .cookie-policy a {
  color: rgba(247, 248, 250, 0.6); }

.theme-dark .cookie-policy li {
  color: rgba(247, 248, 250, 0.95); }

.theme-dark .cookie-policy table {
  color: rgba(247, 248, 250, 0.85); }

.theme-dark .version-display p {
  color: rgba(247, 248, 250, 0.6);
  text-shadow: 0 0 8px #2a2c33; }

.theme-dark .form-fields li.upsell-box {
  background-color: #343740; }
  .theme-dark .form-fields li.upsell-box .custom-check label > span,
  .theme-dark .form-fields li.upsell-box .custom-radio label > span {
    background-color: #2a2c33;
    border: solid 1px #1F2126; }
  .theme-dark .form-fields li.upsell-box .custom-check label:before,
  .theme-dark .form-fields li.upsell-box .custom-radio label:before {
    background-color: #494D59; }
  .theme-dark .form-fields li.upsell-box .custom-check input[type="checkbox"]:checked + label span,
  .theme-dark .form-fields li.upsell-box .custom-check input[type="radio"]:checked + label span,
  .theme-dark .form-fields li.upsell-box .custom-radio input[type="checkbox"]:checked + label span,
  .theme-dark .form-fields li.upsell-box .custom-radio input[type="radio"]:checked + label span {
    background-color: #494D59; }

.theme-dark .modal-outer {
  background-color: #2a2c33; }

.theme-dark .modal-header {
  background-color: #2a2c33; }
  .theme-dark .modal-header .close-modal:before, .theme-dark .modal-header .close-modal:after {
    background-color: #f7f8fa; }

.theme-dark .radio-item-list li .radio-item label {
  color: white;
  background-color: #494D59;
  border: solid 1px #1F2126; }
  .theme-dark .radio-item-list li .radio-item label:before {
    background-color: #2a2c33;
    border: solid 1px #1F2126; }

.theme-dark .radio-item-list li .radio-item input[type="radio"]:checked + label {
  color: #2a2c33;
  background-color: rgba(247, 248, 250, 0.85);
  border-color: rgba(247, 248, 250, 0.85); }
  .theme-dark .radio-item-list li .radio-item input[type="radio"]:checked + label:after {
    background-color: #f7f8fa; }

.theme-dark .radio-item-list li .radio-item input[type="radio"]:disabled + label .result-stripe {
  background-color: #f7f8fa; }

.addon-theme-pink .btn {
  color: white;
  background-color: #ff3057;
  box-shadow: 0 5px 15px 0 rgba(255, 48, 87, 0.25); }
  .addon-theme-pink .btn .btn-icon {
    fill: white; }
  .addon-theme-pink .btn .action-icon span {
    background-color: white; }
    .addon-theme-pink .btn .action-icon span:before, .addon-theme-pink .btn .action-icon span:after {
      background-color: white; }
  .addon-theme-pink .btn .action-icon.back span {
    background: none; }

.addon-theme-pink .side-menu .navbar-action.active {
  background-color: #ff3057; }
  .addon-theme-pink .side-menu .navbar-action.active span:before, .addon-theme-pink .side-menu .navbar-action.active span:after {
    background-color: white; }

.addon-theme-orange .btn {
  color: white;
  background-color: #f5a623;
  box-shadow: 0 5px 15px 0 rgba(245, 166, 35, 0.25); }
  .addon-theme-orange .btn .btn-icon {
    fill: white; }
  .addon-theme-orange .btn .action-icon span {
    background-color: white; }
    .addon-theme-orange .btn .action-icon span:before, .addon-theme-orange .btn .action-icon span:after {
      background-color: white; }
  .addon-theme-orange .btn .action-icon.back span {
    background: none; }

.addon-theme-orange .side-menu .navbar-action.active {
  background-color: #f5a623; }
  .addon-theme-orange .side-menu .navbar-action.active span:before, .addon-theme-orange .side-menu .navbar-action.active span:after {
    background-color: white; }

.addon-theme-blue .btn {
  color: white;
  background-color: #55acee;
  box-shadow: 0 5px 15px 0 rgba(85, 172, 238, 0.25); }
  .addon-theme-blue .btn .btn-icon {
    fill: white; }
  .addon-theme-blue .btn .action-icon span {
    background-color: white; }
    .addon-theme-blue .btn .action-icon span:before, .addon-theme-blue .btn .action-icon span:after {
      background-color: white; }
  .addon-theme-blue .btn .action-icon.back span {
    background: none; }

.addon-theme-blue .side-menu .navbar-action.active {
  background-color: #55acee; }
  .addon-theme-blue .side-menu .navbar-action.active span:before, .addon-theme-blue .side-menu .navbar-action.active span:after {
    background-color: white; }

